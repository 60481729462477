
import { defineComponent, ref, computed, onBeforeMount, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import { loginRequest } from "@/authConfig";

import { isManutenzione } from "@/requests/manutenzione";

import Popper from "vue3-popper";

import { Login } from "@/requests/oldLogin";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Popper,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = useIsAuthenticated();

    const showCreateUser = computed(() => store.getters.showCreateUser);
    const setShowCreateUser = (event) => {
      store.commit("setShowCreateUser", event);
    };
    const name = ref(null);
    const pass = ref(null);

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      name: Yup.string()
        .required("obbligatorio")
        .label("Nome utente")
        .nullable(),
      pass: Yup.string().required("obbligatorio").label("Password").nullable(),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request

      await Login(values).then((res) => {
        if (res.status === 200) {
          store.commit("setCredentials", {
            credentials: values,
            res: res.data,
          });
          router.push({ name: "Verify-Email" });
        } else {
          Swal.fire({
            text: res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. riprovare più tardi",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Riprova",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            // Activate indicator
            // eslint-disable-next-line
            submitButton.value!.setAttribute("data-kt-indicator", "off");
          });
        }

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      });
    };

    const { instance } = useMsal();
    const cookies = ref(document.cookie);

    const loginAdb2c = () => {
      const expires = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      document.cookie = `changed_credentials=true; expires=${expires}; Path=/`;
      instance.loginRedirect(loginRequest);
    };

    return {
      loginAdb2c,
      onSubmitLogin,
      login,
      submitButton,
      cookies,
      isAuthenticated,
      user: computed(() => store.getters.user),
      showCreateUser,
      setShowCreateUser,
      name,
      pass,
      isInManutenzione: computed(() => store.getters.isInManutenzione),
    };
  },
});
